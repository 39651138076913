const allStarTabs = `
SOURCE https://tabs.ultimate-guitar.com/tab/smash_mouth/all_star_tabs_1933121

e|----------9--6--6----------------7----6--6---------------------------------|
B|---7-------------------9--7--7--------------9--9--7------------------------|
G|---------------------------------------------------------------------------|
D|---------------------------------------------------------------------------|
A|---------------------------------------------------------------------------|
E|---------------------------------------------------------------------------|
   Some-    body once told me the world is gonna roll me
`;

export default allStarTabs;
