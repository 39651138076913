/* eslint-disable */
export default `
SOURCE: https://tabs.ultimate-guitar.com/tab/limp_bizkit/rollin_air_raid_vehicle_tabs_1214316


Rollin' (Air Raid Vehicle)
Chocolate Starfish And The Hot Dog Flavoured Water

Tune Down 1 1/2 Step:

6=C#  3=E
5=F#  2=G#
4=B   1=C#


[Intro]

Alright Partner, Keep On Rollin'
Baby You Know What Time It Is

C#|--------------------------------------------------------------------------|
G#|--------------------------------------------------------------------------|
E |-------------------------------------------16--16--16--12--12--12---------|
B |--------------------------------14--14--14--------------------------------|
F#|-------------------------------------------14--14--14--10--10--10---------|
C#|--------------------------------12--12--12--------------------------------|

[Chorus]

C#|--------------------------------------------------------------------------|
G#|--------------------------------------------------------------------------|
E |--------------------------------------------------------------------------|
B |-------4--4-4-2-2-2-------------------------------------------------------|
F#|-2-2-2-2--2-2-0-0-0--5--5-5-4-4---5---------------------------------------|
C#|-0-0-0---------------3--3-3-2-2-0-3--0\---0---0\---0\---------------------|
                                        W/trem Bar

[Verse]

C#|--------------------------------------------------------------------------|
G#|--------------------------------------------------------------------------|
E |--------------------------------------------------------------------------|
B |--------------------------------------------------------------------------|
F#|----------14-14-14--12-12-12----------------------------------------------|
C#|-12-12-12--------------------15-15-15-14-14-12-15-12\--12--12\--12\-------|

[Interlude]

C#|--------------------------------------------------------------------------|
G#|--------------------------------------------------------------------------|
E |--------------------------------------------------------------------------|
B |--------------------------------------------------------------------------|
F#|--------------------------------------------------------------------------|
C#|-12\--12--12\-------------------------------------------------------------|

Order:

Intro 8x
Chorus 4x
Verse 6x
Intro 4x
Chorus 4x
Verse 6x
Intro 4x
Chorus 4x
Interlude 3x
Chorus 4x


************************************

| \  Slide down 

************************************
`