/* eslint-disable */
export default `
 . . . . . . . _,........__
 . . . . . .,-' . . . . . ."\`-.
 . . . . .,' . . . . . . . . . \`-.
 . . . .,' . . . . . . . . . . . .\\
 . . .,' . . . . . . . . . . . . . .
 . . ..'\\ . . . . . . . ,"". . . . \`
 . . ._.'| . . . . . . / | .\` . . . \\
 . . | . | . . . . . .\`-.' .|| . . . \`.
 . . | . | . . . . . .'-._,'|| . . . | \\
 . . .\`.,' . . . . . . \`..,'.' . . . , |\`-.
 . . l . . . . . . . . . . . .'\`. ._/ .| . \`.
 . . \`-.._'- . , . . . . ._ _' . -" \\ .. . . \`
\`."""""'-.\`-...,---------',' . . . . \`. \`....__.
.' . . . .\`"-..___ . . .__,'\\ . . . . .\\ .\\ . . \\
\\_ . . . . . .| . \`""""' . .\`. . . . . . . \\ . . \\
 .\`. . . . . .| . . . . . . .\`. . . . . .| .. . . L
 . .\`. . . . .|\`--...________.'. . . . .j . | . . |
 . . .\`._ . ..' . . .| . . . . .\`. . . .| . , . . |
 . . . . \`--,\\ . . . . . . . . . .\`7""' | ., . . .|
 . . . . . .\` \` . . .\` . . . . . ./ . . | .| . . .| . ._,-'"""\`-.
 . . . . . . \\ \`. . . . . . . . ./ . . .| .' . . .| .,' . . . . .\`.
 . . . . . . .\\ .v.__ .. . . . .' . . . . . \\ . ./| / . . . . . . .\\
 . . . . . . . \\/ . .\`""\\"""""""\`. . . . \\ . \\ ./.'' . . . . . . . .|
 . . . . . . . .\` . . . .. . . . .\`._ ___,j. .\`/ .- . . . ,---. . . |
 . . . . . . . .,\`-. . . .\\ . . . . ." . . \`. .|/ . . . .j . . \` . .|
 . . . . . . . / . .\`. . . \\ . . . / . . . . \\ / . . . . | . . / . .j
 . . . . . . .| . . . \`-. . 7-.._ . . . . . .|" . . . . .' . . . . /
 . . . . . . .| . . . . .\`./_ . .\`| . . . . .| . . . . . .. . . _,'
 . . . . . . .\`. . . . . . / \`----| . . . . .|-............\`---'
 . . . . . . . .\\ . . . . .\\ . . .| . . . . .|
 . . . . . . . ,' . . . . . ) . . \`. . . . . |
 . . . . . . . .7____,,..--' . . ./ . . . . .|
 . . . . . . . . . . . . . . . . .\`---.__,--.'
dots added to appease safari
`;
