/* eslint-disable */
const facepalm = `
THIS IS BROKEN, _ESPECIALLY_ ON FIREFOX
Please submit a PR if you have the fix

............................................________ 
....................................,.-'"...................\`\`~., 
.............................,.-"..................................."-., 
.........................,/...............................................":, 
.....................,?......................................................, 
.................../...........................................................,} 
................./......................................................,:\`^\`..} 
.............../...................................................,:"........./ 
..............?.....__.........................................:\`.........../ 
............./__.(....."~-,_..............................,:\`........../ 
.........../(_...."~,_........"~,_....................,:\`........_/ 
..........{.._$;_......"=,_......."-,_.......,.-~-,},.~";/....} 
...........((.....*~_......."=-._......";,,./\`..../"............../ 
...,,,___.\`~,......"~.,....................\`.....}............../ 
............(....\`=-,,.......\`........................(......;_,,-" 
............/.\`~,......\`-...................................../ 
.............\`~.*-,.....................................|,./.....,__ 
,,_..........}.>-._...................................|..............\`=~-, 
.....\`=~-,__......\`,................................. 
...................\`=~-,,.,............................... 
................................\`:,,...........................\`..............__ 
.....................................\`=-,...................,%\`>--==\`\` 
........................................_..........._,-%.......\` 
..................................., 
`;

export default facepalm 
